<template>
    <div class="row vh-100 player-page" v-if="!loading && session && user.userCode">
        <div class="col col-player"
             :class="{'col-player-full': !sidebar}">
            <div class="container-fluid mt-3">
                <div class="ratio ratio-16x9 border player-container shadow-sm rounded-2 overflow-hidden shadow mb-2">

                    <mux-player :playback-id="session.PrimaryMedia.Uri"
                            :metadata-video-id="`${showCode}_${sessionCode}`"
                            :metadata-viewer-user-id="`${showCode}_${sessionCode}_${user.userCode}`"
                            :metadata-video-title="`${session.Name}`"
                            default-stream-type="live"
                            :placeholder="session.PrimaryMedia.ThumbnailUri"
                            :poster="session.PrimaryMedia.ThumbnailUri"
                            ref="muxVideoPlayer"
                            @playing="onMuxEvent"
                            @pause="onMuxEvent"
                            @ended="onMuxEvent"
                            v-if="session && session.PrimaryMedia.Uri">
                    </mux-player>

                    <div v-else class="w-100 h-100 placeholder-banner-container">
                        <img class="img-fluid w-100" :src="session.PrimaryMedia.ThumbnailUri">

                        <div class="scheduled-overlay border-2 border rounded p-3 pt-2">
                            <span class="d-block fs-4">
                                {{ $t('goLiveMessage') }}
                            </span>
                            <span class="d-block fs-6">
                                {{ $t('goLiveTime', {time: $filters.moment(session.SessionStartDateTime, 'L LT')}) }}
                            </span>
                        </div>  
                    </div>

                </div>
                <h5>{{ session.Name }}</h5>
                <h6>{{ $filters.moment(session.SessionStartDateTime, "LL, LT")}}</h6>
                <div class="description">
                    <template v-if="session.ContactInfo && session.ContactInfo.ContactInfo" 
                            v-for="item in session.ContactInfo.ContactInfo">
                        <div v-if="item.Type == 'Section'">
                            <h5 v-if="item.DisplayText">{{ item.DisplayText }}</h5>
                            <div v-if="item.Details" v-html="processSectionMarkdown(item.Details)">
                            </div>
                        </div>

                        <div v-if="item.Type == 'Website'" class="list-group mb-2">
                            <a :href="item.Value" target="_blank"
                                class="list-group-item list-group-item-action">
                                <h5 v-if="item.DisplayText">
                                    <i class="bi bi-link-45deg me-2"></i>
                                    {{ item.DisplayText }}
                                </h5>
                                <div v-if="item.Details" v-html="processSectionMarkdown(item.Details)">
                                </div>
                            </a>
                        </div>
                        <div v-else-if="item.Type == 'Downloadable'" class="list-group mb-2">
                            <a :href="item.Value" target="_blank" download
                                class="list-group-item list-group-item-action">
                                <h5>
                                    <i class="bi bi-download me-2"></i>
                                    {{ item.DisplayText }}
                                </h5>
                                <p v-if="item.Details">

                                </p>
                            </a>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="col col-sidebar" v-if="sidebar">
            <div class="h-100 d-flex flex-column">
                <div v-if="sidebar.Details && !sidebar.Value" v-html="processSectionMarkdown(sidebar.Details)">
                </div>
                <div v-else-if="sidebar.Value"
                     class="ratio vh-100 sticky-top">
                    <iframe :src="replaceUrlPlaceholders(sidebar.Value)">

                    </iframe>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="loading && !fatalError" class="d-flex justify-content-center vh-100 align-items-center">
        <div class="spinner-border" style="width:4rem; height: 4rem" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else-if="fatalError" class="d-flex justify-content-center vh-100 align-items-center">
        <div class="alert alert-danger">
            <h5>⚠️{{ $t('errors.fatalLoad') }}</h5>
            {{ $t('errors.fatalLoadBody') }}
        </div>
    </div>

    <div v-if="!loading && !fatalError && !showLoginForm && popupIframeUrl"
         class="iframe-prompt">
        <div class="iframe-prompt-content">
            <div class="prompt-iframe border rounded">
                <iframe :src="popupIframeUrl">

                </iframe>
            </div>
            <div class="prompt-dismiss d-flex pt-3">
                <a :href="popupIframeUrl"
                   target="_blank"
                   class="btn btn-primary btn-lg me-2">
                   <i class="bi bi-window-stack"></i>
                </a>
                <button type="button"
                        class="btn btn-danger btn-lg"
                        @click="popupIframeUrl = null">
                    {{ $t('closeButton') }}    
                </button>
            </div>
        </div>
    </div>    

    <div v-if="!loading && !fatalError && showLoginForm"
         class="login-prompt">
        <div class="login-prompt-content p-3 alert alert-light rounded">
            <form @submit.prevent="onSubmitLogin" novalidate>
                <h5>{{ session.Name }}</h5>


                <!--<div class="locale-changer">
                    <select v-model="$i18n.locale" class="form-select form-select-sm">
                        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ $t(`locale.${locale}`) }}</option>
                    </select>
                </div>-->
                <div v-if="loginHeader" v-html="processSectionMarkdown(loginHeader.Details)">

                </div>

                <div class="mb-3">
                    <label for="namefield" class="form-label fs-sm">{{ $t('nameField') }}</label>
                    <input v-model="user.name"
                        @keyup="clearInvalidState"
                        id="namefield"
                        ref="namefield"
                        :disabled="loggingIn"
                        :placeholder="$t('nameField')"
                        class="form-control"
                        type="text"
                        required>
                </div>

                <div class="mb-3">
                    <label for="emailfield" class="form-label fs-sm">{{ $t('emailField') }}</label>
                    <input v-model="user.email"
                    @keyup="clearInvalidState"
                    :disabled="loggingIn"
                    ref="emailfield"
                        id="emailfield"
                        :placeholder="$t('emailField')"
                        class="form-control"
                        type="email"
                        required>

                </div>

                <div v-if="loginFooter" v-html="processSectionMarkdown(loginFooter.Details)">
                    
                </div>

                <div v-if="loginFailedMessage" class="alert alert-danger p-2">
                    <small>{{ loginFailedMessage }}</small>
                </div>

                <button type="submit"
                        :disabled="loggingIn"
                        class="btn btn-primary btn-join d-flex w-100 justify-content-center text-center">
                    <span v-if="!loggingIn">
                        {{ $t('joinButton') }}
                    </span>
                    <div v-else>
                        <div class="spinner-border" style="width:1rem; height: 1rem" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import "@mux/mux-player";
import validator from 'validator';
import * as signalR from '@microsoft/signalr';
import marked from 'marked'
import { nextTick } from "vue";

export default {
    props: ['showCode', 'sessionCode'],
    data() {
        return {
            loading: true,
            loggingIn: false,
            fatalError: false,
            session: null,
            user: {
                name: '',
                nameError: false,
                email: '',
                emailError: false,
                userCode: '',
            },
            sidebar: null,
            loginHeader: null,
            loginFooter: null,
            showLoginForm: true,
            signalR: null,
            signalRConnectionState: 'disconnected',
            statusReportInterval: null,
            stylesheet: '',
            loginInstanceCode: null,
            loginFailedMessage: null,
            totalWatchTimeSeconds: 0,
            lastPlayAt: null,

            popupIframeUrl: null,

            events: [],
        }
    },

    unmounted() {
        window.removeEventListener('beforeunload', this.onExit);
    },

    methods: {

        async onExit() {
            await this.onMuxEvent({type: 'unmount'});
        },

        async onMuxEvent(e) {
            // this.events.splice(0, 0, {
            //     type: e.type,
            //     _time: new Date(),
            //     _vts: e._vts
            // });

            if(!this.lastPlayAt && (e.type == 'playing' || e.type == "playing-sr")) {
                this.lastPlayAt = new Date();
            }

            if(this.lastPlayAt) {
                let playTime = this.lastPlayAt;
                let currentTime = new Date();
                
                let diff = currentTime - playTime;
                diff = diff / (1000.0);
                this.totalWatchTimeSeconds += diff; 
                
                this.lastPlayAt = currentTime;
            }

            if(e.type == 'pause-sr') {
                return;
            }

            if(e.type == 'pause' || e.type == 'player-destroy') {
                this.lastPlayAt = null;
            }

            const playbackKey = 
                this.session && this.session.PrimaryMedia && this.session.PrimaryMedia.Uri
                && this.session.PrimaryMedia.MediaType && this.session.PrimaryMedia.MediaType.indexOf("Mux") != -1
                ? this.session.PrimaryMedia.Uri
                : null;

            const routeUrl = `${process.env.VUE_APP_API_URL}/api/webinar/${this.showCode}/${this.sessionCode}/viewer/watch`;
            await fetch(routeUrl, {
                mode: 'cors',
                method: 'post',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    UserCode: this.user.userCode,
                    EventType: e.type,
                    ReportDateTimeUtc: new Date(),
                    PlaybackKey: playbackKey,
                    InstanceCode: this.loginInstanceCode,
                    TotalWatchTimeSeconds: this.totalWatchTimeSeconds
                })
            });
        },    

        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
            );
        },

        rebuildTheme() {

            let style = document.getElementById('theme-stylesheet');
            if(style) {
                style.innerHTML = '';
            }

            let candidateSheet = this.stylesheet;
            style.innerHTML = candidateSheet;
        },

        clearInvalidState(e) {
            if(e.key == 'Enter' || e.key == 'Return') {
                return;
            }
            e.target.setCustomValidity('');
        },

        createUniqueCode() {
            let guid = this.uuidv4().replace(/-/g, '').toUpperCase();          
            return guid;
        },        

        processSectionMarkdown(text) {
            if(!text) {
                return null;
            }

            text = text.replace("<iframe", "<div class='iframe-ratio'><iframe loading='lazy' ");
            text = text.replace("</iframe>", "</iframe></div>");
            
            const regEx = /\[embed=(.*)\]/g

            let input = text;
            
            let matchResult = input.matchAll(regEx)
            
            let returnContent = input;
            
            for(const m of matchResult) {
              returnContent = returnContent.replace(m[0], `<div class='iframe-ratio'><iframe loading='lazy' src="${m[1]}" allow="fullscreen;camera;microphone"></iframe></div>`);
            }
            
            returnContent = this.replaceUrlPlaceholders(returnContent);

            return marked(returnContent, {
                breaks: true
            });            
        },

        async onSubmitLogin() {
            this.loginFailedMessage = null;
            this.user.nameError = null;
            this.user.emailError = null;

            let nameCheck = this.user.name || '';
            nameCheck = nameCheck.trim();
            this.user.name = nameCheck;

            if(!nameCheck || nameCheck.length == 0) {
                this.user.nameError = 'errors.nameZeroLength';
            }
            else if(nameCheck.length > 250) {
                this.user.nameError = 'errors.nameTooLong';
            }
            
            let emailCheck = this.user.email || '';
            emailCheck = emailCheck.trim();
            this.user.email = emailCheck;

            if(!emailCheck || emailCheck.length == 0) {
                this.user.emailError = 'errors.emailZeroLength';
            }
            else if(emailCheck.length > 250) {
                this.user.emailError = 'errors.emailTooLong';
            }
            else if(emailCheck.indexOf("@") == -1) {
                this.user.emailError = 'errors.emailBadFormat';
            }
            else if(!validator.isEmail(emailCheck + '')) {
                this.user.emailError = 'errors.emailBadFormat'
            }

            this.$refs.emailfield.setCustomValidity(
                this.user.emailError ? this.$t(this.user.emailError) : '');

            this.$refs.namefield.setCustomValidity(
                this.user.nameError ? this.$t(this.user.nameError) : '');

            this.$refs.namefield.checkValidity();
            this.$refs.emailfield.checkValidity();

            if(this.user.nameError) {
                this.$refs.namefield.reportValidity();
                return;
            }

            if(this.user.emailError) {
                this.$refs.emailfield.reportValidity();
                return;
            }

            if(window.localStorage) {
                window.localStorage.setItem('name', this.user.name);
                window.localStorage.setItem('email', this.user.email);
                window.localStorage.setItem('usercode', this.user.userCode);
            }

            this.loggingIn = true;

            try {

                const routeUrl = `${process.env.VUE_APP_API_URL}/api/webinar/${this.showCode}/${this.sessionCode}/viewer/signin`;
                let result = await fetch(routeUrl, {
                    mode: 'cors',
                    method: 'post',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserCode: this.user.userCode,
                        Name: this.user.name,
                        Email: this.user.email
                    })
                });

                if(!result.ok) {
                    this.loggingIn = false;
                    this.loginFailedMessage = this.$t('errors.loginFail');
                    return;
                }

                
                this.lastPlayAt = null;
                this.totalWatchTimeSeconds = 0;

                this.onMuxEvent({type: 'login'});

                this.showLoginForm = false;

                await this.ensureSignalRReady();

                this.sidebar = this.session.ContactInfo.ContactInfo.find(x=>x.Type == 'SidebarEmbed');

                if(this.sidebar && this.sidebar) {

                }

                if(this.$refs.muxVideoPlayer) {
                    try {
                        await this.$refs.muxVideoPlayer.play();
                    } catch(ex) {
                        console.error("Error on autoplay", ex);
                    }
                }

                if(!this.statusReportInterval) {
                    this.statusReportInterval = setInterval(this.onStatusReport, 60000);
                }

                await this.loadSession();

            } catch {
                this.showLoginForm = true;
                this.loggingIn = false;
                this.loginFailedMessage = this.$t('errors.loginFail');
            }

        },

        async onStatusReport() {
            if(this.showLoginForm) {
                return;
            }

            if(!this.$refs.muxVideoPlayer) {
                return;
            }
            console.log(this.$refs.muxVideoPlayer.paused);
            let state = !this.$refs.muxVideoPlayer.paused ? "playing-sr" : 'pause-sr'; 
            this.onMuxEvent({type: state});
        },

        async ensureSignalRReady() {
            try {
                if(!this.signalR) {
                    this.signalR = new signalR.HubConnectionBuilder()
                        .withUrl(`${process.env.VUE_APP_API_URL}/hub/webinars`)
                        .withAutomaticReconnect([0, 1000, 2000, 3000, 5000, 10000])
                        .build();
                    
                    this.signalRConnectionState = 'connecting';

                    this.signalR.on('SessionUpdated', this.onSessionUpdated);
                    this.signalR.on('SessionLaunchUrl', this.onSessionLaunchUrl);
                    this.signalR.on('DismissPostStreamUrl', this.onSessionLaunchUrlDismiss);

                    this.signalR.onreconnecting(() => {
                        this.signalRConnectionState = 'reconnecting';
                    })

                    this.signalR.onreconnected(() => {
                        this.signalRConnectionState = 'connected';
                    })

                    this.signalR.onclose((ex) => {
                        this.signalRConnectionState = 'disconnected';
                        setTimeout(this.ensureSignalRReady, 5000);
                    });

                    await this.signalR.start();

                    await this.signalR.invoke('SubscribeToSession', this.showCode, this.sessionCode, this.user.userCode, this.loginInstanceCode);

                    this.signalRConnectionState = 'connected';
                }
            } catch(ex) {
                console.error("SignalR Connect failed.", ex);
                this.signalR = null;
                this.signalRConnectionState = "disconnected";
                setTimeout(this.ensureSignalRReady, 5000);
            }
        },

        replaceUrlPlaceholders(url) {
            if(url.indexOf("{{ParticipantCode}}") != -1) {
                url = url.replace("{{ParticipantCode}}", `${this.user.userCode}_${this.loginInstanceCode}`);
            }

            if(url.indexOf("{{UserCode}}") != -1) {
                url = url.replace("{{UserCode}}", `${this.user.userCode}`);
            }

            if(url.indexOf("{{LoginInstanceCode}}") != -1) {
                url = url.replace("{{LoginInstanceCode}}", `${this.loginInstanceCode}`);
            }


            if(url.indexOf("{{SessionCode}}") != -1) {
                url = url.replace("{{SessionCode}}", this.sessionCode);
            }

            return url;
        },

        onSessionLaunchUrl(url) {
            url = this.replaceUrlPlaceholders(url);
            this.popupIframeUrl = url;
        },

        onSessionLaunchUrlDismiss() {
            this.popupIframeUrl = null;
        },

        async onSessionUpdated() {
            await this.loadSession();

            nextTick(async () => {
                if(this.session 
                    && this.session.PrimaryMedia.Uri
                    && this.$refs.muxVideoPlayer
                    && this.$refs.muxVideoPlayer.paused)
                {
                    try {
                        await this.$refs.muxVideoPlayer.play();
                    } catch(ex) {
                        console.error("Error on autoplay", ex);
                    }                    
                }

                if(this.session 
                    && !this.session.PrimaryMedia.Uri 
                    && !this.$refs.muxVideoPlayer 
                    && this.lastPlayAt) {
                    // We have a last play at but no end signal?

                    this.onMuxEvent({type: 'player-destroy'});
                }

            });
        },

        async loadSession() {
            const routeUrl = `${process.env.VUE_APP_API_URL}/api/webinar/${this.showCode}/${this.sessionCode}`;
            let result = await fetch(routeUrl, {
                mode: 'cors'
            });

            if(!result.ok) {
                this.loading = false;
                this.fatalError = "Fuck.";
                return;
            }
            
            let session = await result.json();
            this.session = session.Result.Session;
            this.stylesheet = session.Result.Stylesheet
            this.rebuildTheme();

            document.title = `${this.session.Name} | Tractus Webinars`;

            if(this.showLoginForm) {
                this.loginHeader = this.session.ContactInfo.ContactInfo.find(x=>x.Type == 'LoginHeader');
                this.loginFooter = this.session.ContactInfo.ContactInfo.find(x=>x.Type == 'LoginFooter');
            }

            if(!this.showLoginForm) {
                let sidebarCandidate = this.session.ContactInfo.ContactInfo.find(x=>x.Type == 'SidebarEmbed');

                if(this.sidebar && this.sidebar.Value && sidebarCandidate && sidebarCandidate.Value && this.sidebar.Value == sidebarCandidate.Value) {
                    return;
                }

                this.sidebar = sidebarCandidate;
            }
        },
        
        async build() {    
            try {

                if(this.$route.query.lang) {
                    let lang = this.$route.query.lang;

                    this.$i18n.locale = lang;
                }

                await this.loadSession();

                if(window.localStorage) {
                    try {

                        this.user.name = window.localStorage.getItem("name");
                        this.user.email = window.localStorage.getItem("email");
                        this.user.userCode = window.localStorage.getItem("usercode");

                    } catch(ex) {

                    }
                }

                if(!this.user.name) {
                    this.user.name = '';
                }

                if(!this.user.email) {
                    this.user.email = '';
                }

                if(!this.user.userCode) {
                    this.user.userCode = this.createUniqueCode();
                    if(window.localStorage) {
                        window.localStorage.setItem('usercode', this.user.userCode);
                    }
                }

                this.loading = false;



            } catch(ex) {
                console.error('On build:', ex);
                this.fatalError = 'Oops';
            }     
        }
    },

    mounted() {
        this.loginInstanceCode = this.createUniqueCode();
        window.addEventListener('beforeunload', this.onExit);
        this.build();
    }
}
</script>
