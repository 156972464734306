import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import router from './router'
import './style.css'
import marked from 'marked'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs_FR from 'dayjs/locale/fr';
import dayjs_EN from 'dayjs/locale/en';

const userLocale = window.navigator.language || "en";

let localeToUse = userLocale.indexOf("fr") != -1
    ? 'fr'
    : 'en';

dayjs.locale(localeToUse);
dayjs.extend(LocalizedFormat)


window.$markdown = {}
window.$markdown.linkRenderer = new marked.Renderer()
window.$markdown.linkRenderer.link = function (href, title, text) {
    let link = marked.Renderer.prototype.link.apply(this, arguments)
    return link.replace("<a ", "<a target='_blank' ")
}

marked.setOptions({
    renderer: window.$markdown.linkRenderer
})


const i18n = createI18n({
    locale: localeToUse,
    fallbackLocale: 'en',
    messages: {
        en: {
            locale: {
                en: 'English',
                fr: 'Française'
            },
            errors: {
                fatalLoad: "Could not Load Webinar",
                fatalLoadBody: "Could not find your webinar. Please check the URL and try again.",
                emailZeroLength: 'Please enter an email address.',
                emailTooLong: 'Email address is too long.',
                emailBadFormat: 'Please enter a valid email address.',
                nameZeroLength: 'Please enter your name.',
                nameTooLong: 'Name is too long.',
                loginFail: 'Could not join session. Please check your internet connection and try again.'
            },
            nameField: 'Your Full Name (e.g. John Doe)',
            emailField: 'Your Email Address',
            joinButton: 'Join Session',
            goLiveMessage: 'Scheduled for',
            goLiveTime: '{time}',
            closeButton: "Close"
        },

        fr: {
            locale: {
                en: 'English',
                fr: 'Française'
            },
            errors: {
                fatalLoad : "Impossible de charger le webinaire",
                fatalLoadBody: "Impossible de trouver votre webinaire. Veuillez vérifier l'URL et réessayer.",
                emailZeroLength: 'Veuillez saisir une adresse e-mail.',
                emailTooLong: "L'adresse email est trop longue.",
                emailBadFormat: 'Veuillez entrer une adresse e-mail valide.',
                nameZeroLength: 'Veuillez entrer votre nom.',
                nameTooLong : 'Le nom est trop long.',
                loginFail: 'Impossible de rejoindre la session. Veuillez vérifier votre connexion Internet et réessayer.'
            },
            nameField: 'Nom et prénom',
            emailField: 'Votre adresse email',
            joinButton: 'Rejoindre la session',
            goLiveMessage: 'Prévu pour',
            goLiveTime: '{time}',
            closeButton: 'Sortie'

        }
    }
});

var app = createApp(App);

app.config.globalProperties.$filters = {};

function momentFormat(value, format) {
    let toReturn = dayjs(value);
    if(format) {
        return toReturn.format(format);
    }

    return toReturn;
}

app.config.globalProperties.$filters['moment'] = function(value, format) {
    return momentFormat(value, format);
};

app.use(i18n).use(router).mount('#app')
